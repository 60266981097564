import { addClass, removeClass } from '@/utils/utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'RightPanel'
})
export default class extends Vue {
  @Prop({ default: true }) private clickNotClose!: boolean;
  @Prop({ default: 94 }) private buttonTop!: number;
  @Prop({ default: 'el-icon-setting' }) private buttonIcon!: string;
  @Prop({ }) private color!: string;

  private show = false;

  @Watch('show')
  private onShowChange(value: boolean) {
    if (value && !this.clickNotClose) {
      this.addEventClick();
    }
    if (value) {
      addClass(document.body, 'showRightPanel');
      var elements = document.body.getElementsByClassName("rightPanel-container");
      for (const elem of elements) {
        if(!elem.classList.contains('show')) elem.classList.add("hidden")
      }
    } else {
      removeClass(document.body, 'showRightPanel');
      var elements = document.body.getElementsByClassName("rightPanel-container");
      for (const elem of elements) {
        if(elem.classList.contains('hidden')) elem.classList.remove("hidden")
      }
    }
  }

  mounted() {
    this.insertToBody();
  }

  beforeDestroy() {
    const elx = this.$refs.rightPanel as Element;
    elx.remove();
  }

  private addEventClick() {
    window.addEventListener('click', this.closeSidebar);
  }

  public closeSidebar(ev: MouseEvent) {
    const parent = (ev.target as HTMLElement).closest('.rightPanel');
    if (!parent) {
      this.show = false;
      window.removeEventListener('click', this.closeSidebar);
    }
  }

  public insertToBody() {
    const elx = this.$refs.rightPanel as Element;
    const body = document.querySelector('body');
    if (body) {
      body.insertBefore(elx, body.firstChild);
    }

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
